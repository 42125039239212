@tailwind base;
@tailwind components;
@tailwind utilities;

.center-iframe {
    margin-top: 2%;
    justify-content: center;
    align-items: center;
    display: flex;
}

body,
html,
#root {
    margin: 0;
    padding: 0;
}

main {
    width: 100%;
    max-width: 100%;
    overflow-x: hidden;
}

#particles-js {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
}

.content-wrapper {
    z-index: 1;
}

.custom-bg-color {
    background-color: #E6DBD0;
}

.border-purple {
    border: 2px solid purple;
}

.experience-card {
    transition: all 0.3s ease-in-out;
  }
  
.experience-card:hover {
transform: translateY(-6px);
box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
}